import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenuOpen } from '../../../redux/actions';

import Logo from '../../../assets/img/face-recognization-01.jpg'

class Sidebar extends Component {

  constructor(){
    super();
    this.state = {
      user: null,
    };
  }

  componentWillMount(){  
    // Check if is Logged in
    let isLocalAuth = localStorage.getItem('isLoggedIn')
    let isSessionAuth = sessionStorage.getItem('isLoggedIn');

    if(isLocalAuth){
      this.setState({ user: JSON.parse(localStorage.getItem('user')) })
    }
    else if(isSessionAuth){
      this.setState({ user: JSON.parse(sessionStorage.getItem('user')) })
    }else{
      this.props.history.push("/");
    }
  }

  render() {
    const { clickMenuOpen, toggled } = this.props;
    return (
      <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">

        {/* <!-- Sidebar - Brand --> */}
        <Link className="sidebar-brand d-flex align-items-center justify-content-left bg-light" to="/">
          <img src={Logo} className="ml-3" alt="Logo" height={40} width={40}/>
          <span className={toggled?"d-none":"text-primary ml-3"}>Att System</span>
        </Link>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span></Link>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">
          Interface
        </div>

        {(this.state.user.user_type === "admin")? 
        <li className="nav-item">
          <a className='nav-link collapsed' href="#subscriptionPlan" data-toggle="collapse" data-target="#subscriptionPlan"  aria-controls="subscriptionPlan">
            <i className="fas fa-fw fa-cog"></i>
            <span>Subscription Plan</span>
          </a>
          <div id="subscriptionPlan" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Subscription Plan</h6>
              <Link className="collapse-item" to="/subscription-plan/add">Add</Link>
              <Link className="collapse-item" to="/subscription-plan">View</Link>
            </div>
          </div>
        </li>
        :null}

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        {(this.state.user !== null)?
          (this.state.user.user_type === "admin")?
            <li className="nav-item">
              <a className='nav-link collapsed' href="#company" data-toggle="collapse" data-target="#company"  aria-controls="company">
                <i className="fas fa-fw fa-building"></i>
                <span>Company</span>
              </a>
              <div id="company" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Company</h6>
                  <Link className="collapse-item" to="/company/add">Add</Link>
                  <Link className="collapse-item" to="/company">View</Link>
                </div>
              </div>
            </li>
          :null
        :null}

        <li className="nav-item">
          <a className='nav-link collapsed' href="#department" data-toggle="collapse" data-target="#department"  aria-controls="department">
            <i className="fas fa-fw fa-cog"></i>
            <span>Department</span>
          </a>
          <div id="department" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Department</h6>
              {(this.state.user !== null)?
                (this.state.user.user_type === "company")?
                  <Link className="collapse-item" to="/department/add">Add</Link>
                :null
              :null}
              <Link className="collapse-item" to="/department">View</Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a className='nav-link collapsed' href="#shift" data-toggle="collapse" data-target="#shift"  aria-controls="shift">
            <i className="fas fa-fw fa-users-cog"></i>
            <span>Shift</span>
          </a>
          <div id="shift" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Shift</h6>
              {(this.state.user !== null)?
                (this.state.user.user_type === "company")?
                  <Link className="collapse-item" to="/shift/add">Add</Link>
                :null
              :null}
              <Link className="collapse-item" to="/shift">View</Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <Link className='nav-link collapsed' to="/employee">
            <i className="fas fa-users fa-cog"></i>
            <span>Employees</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className='nav-link collapsed' to="/employee/attendance">
            <i className="fas fa-users fa-cog"></i>
            <span>All Attendance</span>
          </Link>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button onClick={() => { clickMenuOpen() }} className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>

      </ul>)
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = store => ({
  toggled: store.menuState.menuOpen
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);