import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const ViewDepartment = () => {
    const [departments, setDepartments] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(true);

    let history = useHistory();

    const getToken = () => { 
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
            setUser(JSON.parse(localStorage.getItem('user')))
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
            setUser(JSON.parse(sessionStorage.getItem('user')))
        }
    }

    const getDepartment = () => { 
        var id = ""
        if(user.user_type === "company"){
            id = user._id;
        }
        const params = {
            id: id
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/department-list", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data);
                setDepartments(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if(token !== null && user !== null){
            getDepartment();
        }
        // eslint-disable-next-line
    }, [token, user]);

    const deletePlan = (id) => {
        var accept = window.confirm("Are you sure you want to delete department?");
        if(accept)
        {
            setIsLoading(true);

            const params = {
                id: id
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

            axios
            .post(BASE_URL+"/admin/delete-department", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                setIsLoading(false);
                if(res.data.status === 'success'){
                    Toast.fire({
                        icon: 'success',
                        title: "Department Deleted Successfully"
                    })
                    getDepartment();
                }else{
                    console.log(res.data);
                    Toast.fire({
                        icon: 'error',
                        title: "Unable to delete department"
                    })
                }
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: "Unexpected Error"
                })
                setIsLoading(false);
                console.log(err);
            });
        }
    }

    return(
        <div className="container-fluid">
            <div className="card-body row">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{width: 10}}>ID</th>
                            {(user !== null)? 
                                (user.user_type === "admin")? 
                                    <th>Company</th>
                                :null
                            :null}
                            <th>Department</th>
                            <th style={{width: 120}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && <tr><td className="text-center" colSpan="3">Loading Please wait...</td></tr>}
                        {(departments!==null)? 
                            (departments.length > 0)? 
                                departments.map((department, i) => (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        {(user !== null)? 
                                            (user.user_type === "admin")? 
                                                <th>{(department.company !== null)?department.company.full_name:null}</th>
                                            :null
                                        :null}
                                        <td>{department.name}</td>
                                        <td>
                                            <button className="btn btn-info btn-sm" onClick={() => history.push("/department/update/"+department._id)}><i className="fas fa-pen"></i></button>
                                            <button className="btn btn-danger btn-sm ml-1" onClick={() => deletePlan(department._id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))
                            :
                            <tr><td className="text-center" colSpan="3">No Data Found</td></tr>
                        :
                        null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewDepartment;