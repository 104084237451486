import React from 'react';

const ValidateInput = ({ className, title, id, value, type, errors, searchFor, setValue }) => { 
    return (
        <div className={`form-group ${className}`}>
            {errors === null?
                <div>
                    <label htmlFor={id}>{title} <span className='text-danger'>*</span></label>
                    <input type={type} className="form-control form-control-user" id={id} placeholder={`Enter ${title}`} value={value} onChange={(e) => setValue(e.target.value)} />
                </div>
                :
                (errors.filter(error => error.param === searchFor).length > 0)?
                    errors.filter(error => error.param === searchFor).map((error, i) => (
                        (i === 0) && <div key={i}>
                            <label htmlFor={id}>{title}</label>
                            <input type={type} className="form-control form-control-user is-invalid" id={id} placeholder={`Enter ${title}`} onChange={(e) => setValue(e.target.value)} />
                            <div className="invalid-feedback ml-3">
                            {error.msg}
                            </div>
                        </div>
                    ))
                    :
                    <div>
                        <label htmlFor={id}>{title}</label>
                        <input type={type} className="form-control form-control-user" id={id} placeholder={`Enter ${title}`} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
            }
        </div>
    )
}

export default ValidateInput;