import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ProtectedRoute } from './protected.route.js' 

//Admin Pages
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Company from "./pages/Company";
import ViewCompany from "./pages/Company/View";
import UpdateCompany from "./pages/Company/Update";
import ViewDepartment from "./pages/Department/View";
import Department from "./pages/Department";
import UpdateDepartment from "./pages/Department/Update";
import ViewShift from "./pages/Shift/View";
import Shift from "./pages/Shift";
import UpdateShift from "./pages/Shift/Update";
import Employee from "./pages/Employee";
import UpdateEmployee from "./pages/Employee/Update";
import Attendance from "./pages/Employee/Attendance";
import AllAttendance from "./pages/Employee/AllAttendance";
import ChangePassword from "./pages/ChangePassword";
import ViewSubscriptionPlan from "./pages/SubscriptionPlan/View";
import SubscriptionPlan from "./pages/SubscriptionPlan";
import UpdateSubscriptionPlan from "./pages/SubscriptionPlan/Update";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={SignIn} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/company" component={ViewCompany} />
            <ProtectedRoute exact path="/company/add" component={Company} />
            <ProtectedRoute exact path="/company/update/:id" component={UpdateCompany} />
            <ProtectedRoute exact path="/department" component={ViewDepartment} />
            <ProtectedRoute exact path="/department/add" component={Department} />
            <ProtectedRoute exact path="/department/update/:id" component={UpdateDepartment} />
            <ProtectedRoute exact path="/shift" component={ViewShift} />
            <ProtectedRoute exact path="/shift/add" component={Shift} />
            <ProtectedRoute exact path="/shift/update/:id" component={UpdateShift} />
            <ProtectedRoute exact path="/subscription-plan" component={ViewSubscriptionPlan}/>
            <ProtectedRoute exact path="/subscription-plan/add" component={SubscriptionPlan}/>
            <ProtectedRoute exact path="/subscription-plan/update/:id" component={UpdateSubscriptionPlan}/>
            <ProtectedRoute exact path="/employee" component={Employee} />
            <ProtectedRoute exact path="/employee/update/:id" component={UpdateEmployee} />
            <ProtectedRoute exact path="/employee/attendance" component={AllAttendance} />
            <ProtectedRoute exact path="/employee/attendance/:id" component={Attendance} />
            <ProtectedRoute exact path="/change-password" component={ChangePassword} />
            <Route path="/signup" component={SignUp} />
            <Route path="*" component={NotFound} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
