import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import ValidateInput from '../../components/ValidateInput'
import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const SubscriptionPlan = () => {
    const [planName, setPlanName] = useState("");
    const [duration, setDuration] = useState("");
    const [price, setPrice] = useState("");
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    let history = useHistory();

    useEffect(() => {
        let isLocalAuth = localStorage.getItem('isLoggedIn')
        let isSessionAuth = sessionStorage.getItem('isLoggedIn');
    
        if(isLocalAuth){
            setToken(localStorage.getItem('token'))
        }
        else if(isSessionAuth){
            setToken(sessionStorage.getItem('token'))
        }
    }, [])
    
    function handleSubmit(event) 
    {
        setIsLoading(true);
        event.preventDefault();

        const params = {
            plan_name: planName,
            price: price,
            duration: duration
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/create-subscription-plan", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data);
                history.push("/subscription-plan");
            }else if(res.data.status === "validation_error"){
                setErrors(res.data.errors);
                console.log(errors);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
                setErrors(null);
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
                setErrors(null);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            if(err.response.status === 401){
                Toast.fire({
                    icon: 'error',
                    title: "Unauthorized Access"
                })
            }
            console.log(err);
        });
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h3>Create Subscription Plan</h3>
                    </div>
                    <form onSubmit={handleSubmit} className="row justify-content-center">
                        <div className="card-body col-8">
                            <ValidateInput 
                                title="Subscription Plan Name"
                                id="subscriptionPlanName"
                                value={planName}
                                type="text"
                                errors={errors}
                                searchFor="plan_name"
                                setValue={setPlanName}
                            />
                            <ValidateInput 
                                title="Plan Price (in INR)"
                                id="planPrice"
                                value={price}
                                type="number"
                                errors={errors}
                                searchFor="price"
                                setValue={setPrice}
                            />
                            <ValidateInput 
                                title="Plan Duration (in Days)"
                                id="planDuration"
                                value={duration}
                                type="number"
                                errors={errors}
                                searchFor="duration"
                                setValue={setDuration}
                            />
                            {!isLoading && <button className="btn btn-primary mt-3">ADD</button>}
                            {isLoading && <button className="btn btn-primary mt-3" disabled><i className="fas fa-spinner fa-spin mr-1"></i> ADDING...</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlan;