import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import moment from 'moment';

import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const Attendance = () => {
    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // eslint-disable-next-line
    const [limit, setLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    let { id } = useParams();

    const getToken = () => { 
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
        }
    }

    const getUsers = () => { 
        const params = {
            employee: id,
            limit: limit,
            page: pageNumber * limit,
            start: startDate,
            end: endDate,
            id: ""
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/employee-attend-list", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data.totalCount);
                setTotalUsers(res.data.totalCount);
                setUsers(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if(token !== null){
            getUsers();
        }
        // eslint-disable-next-line
    }, [token, limit, pageNumber]); 

    return(
        <div className="container-fluid">
            <div className="card-body row">
                <label className="col-2 mt-1">Start Date</label>
                <input type="date" className="form-control col-2" onChange={(e) => setStartDate(e.target.value)} />
                <div className="col-2"></div>
                <label className="col-2 mt-1">End Date</label>
                <input type="date" className="form-control col-2" onChange={(e) => setEndDate(e.target.value)} />
                <div className="col-1"></div>
                <div className="col-1">
                    <button className="btn btn-primary" onClick={() => getUsers()}>Filter</button>
                </div>
            </div>
            <div className="card-body row">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{width: 10}}>ID</th>
                            <th>Employee Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Location</th>
                            <th>Photos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && <tr><td className="text-center" colSpan="6">Loading Please wait...</td></tr>}
                        {(users!==null)? 
                            (users.length > 0)? 
                                users.map((user, i) => (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{(user.employee)?user.employee.name:null}</td>
                                        <td>{moment(user.created_date).format('D-MM-YYYY')}</td>
                                        <td>{moment(user.created_date).format('hh:mm A')}</td>
                                        <td>
                                            <a href={`https://www.google.co.in/maps/@${user.position.coordinates[0]},${user.position.coordinates[1]},19.11z`} target="_blank" rel="noopener noreferrer">View on Map</a>
                                        </td>
                                        <td>
                                            <div className="flex" style={{ maxWidth: 300 }}>
                                                {user.photos.map(photo => (
                                                    <div key={photo._id} className="float-left mr-1">
                                                        <img src={photo.file} alt={photo._id} height="60" width="50" />
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            :
                            <tr><td className="text-center" colSpan="6">No Data Found</td></tr>
                        :
                        null
                        }
                    </tbody>
                </table>
            </div>
            <div>
                {(users != null)?
                    <ReactPaginate 
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={Math.ceil(totalUsers / limit)}
                        onPageChange={changePage}
                        containerClassName={"pagination mt-3"}
                        pageClassName={"paginate_button page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"previous"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"next"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                    />
                :null}
            </div>
        </div>
    )
}

export default Attendance;