import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';

import { BASE_URL } from '../../env';

class SignIn extends Component {
  constructor(){
    super();
    this.state = {
      email: "",
      password: "",
      status: null,
      errors: null,
      error: null,
      isLoading: false,
      rememberMe: false
    };
  }

  componentWillMount(){
    document.getElementById('body').className='bg-gradient-primary'

    // check if rememberMe is active
    let remember = localStorage.getItem('rememberMe');

    // Check if is Logged in
    let isLocalAuth = localStorage.getItem('isLoggedIn')
    let isSessionAuth = sessionStorage.getItem('isLoggedIn');

    if(isLocalAuth || isSessionAuth){
      this.props.history.push("/dashboard");
    }
    if(remember && remember===true){
      this.setState({ rememberMe: remember });
    }
  }

  // handle remember me checkbox
  handleRememberMe = () => {
    if(this.state.rememberMe){
      this.setState({ rememberMe: false });
    }else{
      this.setState({ rememberMe: true });
    }
  }

  handleSignIn = (e) => {
    this.setState({ isLoading: true});
    e.preventDefault();

    const params = {
        email: this.state.email,
        password: this.state.password
    }

    // converting (json --> form-urlencoded)
    const data = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

    axios
      .post(BASE_URL+"/admin/login", data,{
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
      .then((res) => {
        // Validating form
          this.setState({ isLoading: false});
          if(res.data.status === 'success'){
            if(this.state.rememberMe){
              localStorage.setItem('isLoggedIn', true);
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('rememberMe', true);
              localStorage.setItem('user', JSON.stringify(res.data.result))
            }else{
              localStorage.clear();
              sessionStorage.setItem('isLoggedIn', true);
              sessionStorage.setItem('token', res.data.token);
              sessionStorage.setItem('user', JSON.stringify(res.data.result))
              localStorage.setItem('rememberMe', false);
            }
            console.log(res.data);
            this.props.history.push("/dashboard");
          }else if(res.data.status === "validation_error"){
            this.setState({ errors: res.data.errors });
            this.setState({ error: null });
            console.log(this.state.errors);
          }else if(res.data.status === "error"){
            this.setState({ error: res.data.message });
            this.setState({ errors: null });
          }else{
            this.setState({ error: "Cannot process request" });
            this.setState({ errors: null });
          }
      })
      .catch((err) => {
        this.setState({ isLoading: false});
        this.setState({ error: "Please Try Again" });
        console.log(err);
      });
  }

  render() {
    return (
      <div className="container">
        {/* <!-- Outer Row --> */}
        <div className="row justify-content-center">
    
          <div className="col-xl-10 col-lg-12 col-md-9">
    
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form onSubmit={this.handleSignIn} className="user">
                        {this.state.errors === null?
                          <div className="form-group">
                            <input type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                          </div>
                        :
                          (this.state.errors.filter(error => error.param === "email").length > 0)?
                            this.state.errors.filter(error => error.param === "email").map((error, i) => (
                              (i === 0) && <div className="form-group" key={i}>
                                <input type="email" className="form-control form-control-user is-invalid" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." onChange={(e) => this.setState({ email: e.target.value })} />
                                <div className="invalid-feedback ml-3">
                                  {error.msg}
                                </div>
                              </div>
                            ))
                          :
                          <div className="form-group">
                            <input type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                          </div>
                        }
                        {this.state.errors === null?
                          <div className="form-group">
                            <input type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                          </div>
                        :
                          (this.state.errors.filter(error => error.param === "password").length > 0)?
                            this.state.errors.filter(error => error.param === "password").map((error, i) => (
                              (i === 0) && <div className="form-group" key={i}>
                              <input type="password" className="form-control form-control-user is-invalid" id="exampleInputPassword" placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} />
                                <div className="invalid-feedback ml-3">
                                  {error.msg}
                                </div>
                              </div>
                            ))
                          :
                          <div className="form-group">
                            <input type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                          </div>
                        }
                        <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input type="checkbox" className="custom-control-input" id="customCheck" onChange={(e) => this.handleRememberMe(e.target.value)} defaultChecked={this.state.rememberMe}/>
                            <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                          </div>
                        </div>
                        {
                          (this.state.error !== null)?
                          <div className="alert alert-danger" role="alert">
                            {this.state.error}
                          </div>
                          :null
                        }
                        {!this.state.isLoading && <button  type="submit" className="btn btn-primary btn-user btn-block">
                          Log In
                        </button>}
                        {this.state.isLoading && <button type="button" className="btn btn-primary btn-user btn-block" disabled>
                          <i className="fas fa-spinner fa-spin mr-1"></i> Logging In...
                        </button>}
                      </form>
                      <hr/>
                      <div className="text-center">
                        <a className="small" href="forgot-password.html">Forgot Password?</a>
                      </div>
                      <div className="text-center">
                        <Link className="small" to="/signup">Create an Account!</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
          </div>
    
        </div>
    
      </div>
    )
  }
}

export default withRouter(SignIn);