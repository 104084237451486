import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const AllAttendance = () => {
    const [users, setUsers] = useState(null);
    const [user, setUser] = useState(null);
    const [employee, setEmployee] = useState("");
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // eslint-disable-next-line
    const [limit, setLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const getToken = () => { 
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
            setUser(JSON.parse(localStorage.getItem('user')))
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
            setUser(JSON.parse(sessionStorage.getItem('user')))
        }
    }

    const getUsers = () => {
        var id = ""
        if(user.user_type === "company"){
            id = user._id;
        }

        const params = {
            employee: employee,
            limit: limit,
            page: pageNumber * limit,
            start: startDate,
            end: endDate,
            id:id
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/employee-attend-list", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data.totalCount);
                setTotalUsers(res.data.totalCount);
                setUsers(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if(token !== null && user !== null){
            getUsers();
        }
        // eslint-disable-next-line
    }, [token, user, limit, pageNumber, employee]); 

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results);
        var id = ""
        if(user !== null && user.user_type === "company"){
            id = user._id;
        }

        const params = {
            id:id,
            name: string,
            limit: 15,
            page: 0
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/select-employee-list", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                setItems(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }
    
    const handleOnSelect = (item) => {
        // the item selected
        setEmployee(item.id);
    }

    return(
        <div className="container-fluid">
            <div className="card-body row">
                <div className="col-3 mt-4">
                    <ReactSearchAutocomplete
                        items={items}
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onClear={() => setEmployee("")}
                        styling={{position: "absolute",zIndex:99,borderRadius: "5px"}}
                        placeholder="Search"
                    />
                </div>
                <div className="col-1"></div>
                <div className="col-2">
                    <label>Start Date</label>
                    <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className="col-1"></div>
                <div className="col-2">
                    <label>End Date</label>
                    <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className="col-1"></div>
                <div className="col-1 mt-4">
                    <button className="btn btn-primary" onClick={() => getUsers()}>Filter</button>
                </div>
            </div>
            <div className="card-body row">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{width: 10}}>ID</th>
                            <th>Employee Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Location</th>
                            <th>Photos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && <tr><td className="text-center" colSpan="8">Loading Please wait...</td></tr>}
                        {(users!==null)? 
                            (users.length > 0)? 
                                users.map((user, i) => (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{(user.employee)?user.employee.name:null}</td>
                                        <td>{(user.employee)?user.employee.email:null}</td>
                                        <td>{(user.employee)?user.employee.mobile:null}</td>
                                        <td>{moment(user.created_date).format('D-MM-YYYY')}</td>
                                        <td>{moment(user.created_date).format('hh:mm A')}</td>
                                        <td>
                                            <a href={`https://www.google.co.in/maps/@${user.position.coordinates[0]},${user.position.coordinates[1]},19.11z`} target="_blank" rel="noopener noreferrer">View on Map</a>
                                        </td>
                                        <td>
                                            <div className="flex" style={{ maxWidth: 300 }}>
                                                {user.photos.map(photo => (
                                                    <div key={photo._id} className="float-left mr-1">
                                                        <img src={photo.file} alt={photo._id} height="60" width="50" />
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            :
                            <tr><td className="text-center" colSpan="8">No Data Found</td></tr>
                        :
                        null
                        }
                    </tbody>
                </table>
            </div>
            <div>
                {(users != null)?
                    <ReactPaginate 
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={Math.ceil(totalUsers / limit)}
                        onPageChange={changePage}
                        containerClassName={"pagination mt-3"}
                        pageClassName={"paginate_button page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"previous"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"next"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                    />
                :null}
            </div>
        </div>
    )
}

export default AllAttendance;