import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import ValidateInput from '../../components/ValidateInput'
import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const ChangePassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [user, setUser] = useState("");
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    let history = useHistory();

    useEffect(() => {
        let isLocalAuth = localStorage.getItem('isLoggedIn')
        let isSessionAuth = sessionStorage.getItem('isLoggedIn');
    
        if(isLocalAuth){
            setToken(localStorage.getItem('token'));
            setUser(JSON.parse(localStorage.getItem('user')));
        }
        else if(isSessionAuth){
            setToken(sessionStorage.getItem('token'));
            setUser(JSON.parse(sessionStorage.getItem('user')));
        }
    }, [])
    
    function handleSubmit(event) 
    {
        event.preventDefault();

        if(password !== "" && confirmPassword !== ""){
            if(password === confirmPassword){
                setIsLoading(true);

                const params = {
                    id: user._id,
                    password: password
                }
        
                // converting (json --> form-urlencoded)
                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        
                axios
                .post(BASE_URL+"/admin/change-password", data,{
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'authorization': token
                        }
                    })
                .then((res) => {
                    // Validating form
                    setIsLoading(false);
                    if(res.data.status === 'success'){
                        console.log(res.data);
                        history.push("/dashboard");
                    }else if(res.data.status === "validation_error"){
                        setErrors(res.data.errors);
                        console.log(errors);
                    }else if(res.data.status === "error"){
                        Toast.fire({
                            icon: 'error',
                            title: res.data.message
                        })
                        setErrors(null);
                    }else{
                        Toast.fire({
                            icon: 'error',
                            title: "Cannot process request"
                        })
                        setErrors(null);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if(err.response.status === 401){
                        Toast.fire({
                            icon: 'error',
                            title: "Unauthorized Access"
                        })
                    }
                    console.log(err);
                });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Confirm Password does not match"
                })
            }
        }else{
            Toast.fire({
                icon: 'error',
                title: "Fields cannot be empty"
            })
        }
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h3>Change Password</h3>
                    </div>
                    <form onSubmit={handleSubmit} className="row justify-content-center">
                        <div className="card-body col-8">
                            <ValidateInput 
                                title="Enter Password"
                                id="password"
                                value={password}
                                type="password"
                                errors={errors}
                                searchFor="password"
                                setValue={setPassword}
                            />
                            <ValidateInput 
                                title="Confirm Password"
                                id="confirmPassword"
                                value={confirmPassword}
                                type="password"
                                errors={errors}
                                searchFor="confirm_password"
                                setValue={setConfirmPassword}
                            />
                            {!isLoading && <button className="btn btn-primary mt-3">Change</button>}
                            {isLoading && <button className="btn btn-primary mt-3" disabled><i className="fas fa-spinner fa-spin mr-1"></i> Changing...</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;