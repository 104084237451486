import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';

import ValidateInput from '../../components/ValidateInput'
import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const UpdateCompany = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [plan, setPlan] = useState("");
    const [planManual, setPlanManual] = useState("");
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [plans, setPlans] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [oldImage, setOldImage] = useState(null);
    const [logo, setLogo] = useState({
        image: null,
        base: null
    });

    let { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
        }
    }, [])

    useEffect(() => {
        if(token !== null){
            const params = {
                id: id
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    
            axios
            .post(BASE_URL+"/admin/get-profile", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                if(res.data.status === 'success'){
                    console.log(res.data);
                    setName(res.data.result.full_name);
                    setEmail(res.data.result.email);
                    setPlanManual(res.data.result.subscription_till);
                    setMobile(res.data.result.mobile);
                    setOldImage(res.data.result.profile_image);
                }else if(res.data.status === "error"){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.message
                    })
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: "Cannot process request"
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [token, id])

    const getSubscription = () => { 
        axios
        .post(BASE_URL+"/admin/subscription-plan-list", {},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            if(res.data.status === 'success'){
                console.log(res.data);
                setPlans(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        if(token !== null){
            getSubscription();
        }
        // eslint-disable-next-line
    }, [token]);

    function handleSubmit(event){
        event.preventDefault();

        setIsLoading(true);

        let formData = new FormData();
        formData.append('photo', logo.image);
        formData.append('id', id);
        formData.append('full_name', name);
        formData.append('email', email);
        formData.append('mobile', mobile);
        formData.append('subscription_till', planManual);
        formData.append('old_image', oldImage);

        axios
        .post(BASE_URL+"/admin/update-profile", formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data);
                Toast.fire({
                    icon: 'success',
                    title: "Company updated successfully"
                })
                history.push('/company');
            }else if(res.data.status === "validation_error"){
                setErrors(res.data.errors);
                console.log(errors);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
                setErrors(null);
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
                setErrors(null);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            if(err.response.status === 401){
                Toast.fire({
                    icon: 'error',
                    title: "Unauthorized Access"
                })
            }
            console.log(err);
        });
    }

    const handleFileUpload = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0]);
        let image_as_file = e.currentTarget.files[0];

        setLogo({
            image: image_as_file,
            base: image_as_base64
        });
    }

    function handleSubscription(event){
        event.preventDefault();

        if(plan !== ""){
            setIsSubscribing(true);

            const params = {
                id: id,
                subscription_plan: plan
            }

            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

            axios
            .post(BASE_URL+"/admin/update-user-subscription", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                setIsSubscribing(false);
                if(res.data.status === 'success'){
                    console.log(res.data);
                    Toast.fire({
                        icon: 'success',
                        title: "Subscribed successfully"
                    })
                }else if(res.data.status === "error"){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.message
                    })
                    setErrors(null);
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: "Cannot process request"
                    })
                    setErrors(null);
                }
            })
            .catch((err) => {
                setIsSubscribing(false);
                if(err.response.status === 401){
                    Toast.fire({
                        icon: 'error',
                        title: "Unauthorized Access"
                    })
                }
                console.log(err);
            });
        }else{
            Toast.fire({
                icon: 'error',
                title: "Please select subscription plan"
            })
        }
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h3>Update Company</h3>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-8">
                            <form onSubmit={handleSubscription} className="row">
                                <div className="form-group col-8">
                                    <select className="form-control" id="subscription" value={plan} onChange={(e) => setPlan(e.target.value)} required>
                                        <option>Select Subscription Plan</option>
                                        {
                                            (plans != null)? 
                                                (plans.length > 0)?
                                                    plans.map(plan => (
                                                        <option value={plan._id}>{plan.plan_name} - Rs.{plan.price} ({plan.duration} days)</option>
                                                    ))
                                                :null
                                            :null
                                        }
                                    </select>
                                </div>
                                <div className="col-4">
                                    {!isSubscribing && <button type="submit" className="btn btn-primary">Update Subscription</button>}
                                    {isSubscribing && <button type="submit" className="btn btn-primary" disabled><i className="fas fa-spinner fa-spin mr-1"></i> Updating Subscription...</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr />
                    <form onSubmit={handleSubmit} className="row justify-content-center">
                        <div className="card-body col-8">
                            <ValidateInput 
                                title="Company Name"
                                id="organizationName"
                                value={name}
                                type="text"
                                errors={errors}
                                searchFor="full_name"
                                setValue={setName}
                            />
                            <ValidateInput 
                                title="Company Email"
                                id="organizationEmail"
                                value={email}
                                type="email"
                                errors={errors}
                                searchFor="email"
                                setValue={setEmail}
                            />
                            <ValidateInput 
                                title="Company Mobile"
                                id="organizationMobile"
                                value={mobile}
                                type="text"
                                errors={errors}
                                searchFor="mobile"
                                setValue={setMobile}
                            />
                            <div className="form-group">
                                <label htmlFor="subscriptionPlan">Subscription Till - <span className="ml-1">{moment(planManual).format("DD-MM-YYYY")}</span></label>
                                <input type="date" className="form-control" defaultValue={moment(planManual).format("YYYY-MM-DD")} id="subscriptionPlan" onChange={(e) => setPlanManual(e.target.value)}/>
                            </div>
                            <div className="form-group col-6">
                                <label className="mr-3">Company Logo</label>
                                <input type="file" name="file" className="ml-3" onChange={e => handleFileUpload(e)}/>
                            </div>
                            {!isLoading && <button className="btn btn-primary mt-3">UPDATE CHANGES</button>}
                            {isLoading && <button className="btn btn-primary mt-3" disabled><i className="fas fa-spinner fa-spin mr-1"></i> UPDATING CHANGES...</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateCompany;