import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import ValidateInput from '../../components/ValidateInput'
import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const UpdateShift = () => {
    const [name, setName] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    let history = useHistory();
    let { id } = useParams();

    useEffect(() => {
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
        }
    }, [])

    useEffect(() => {
        if(token !== null){
            const params = {
                id: id
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    
            axios
            .post(BASE_URL+"/admin/get-shift", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                if(res.data.status === 'success'){
                    console.log(res.data);
                    setName(res.data.result.name);
                    setTo(res.data.result.to);
                    setFrom(res.data.result.from);
                }else if(res.data.status === "error"){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.message
                    })
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: "Cannot process request"
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [token, id])

    function handleSubmit(event){
        setIsLoading(true);
        event.preventDefault();

        const params = {
            id: id,
            name: name,
            to: to,
            from: from
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/update-shift", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data);
                Toast.fire({
                    icon: 'success',
                    title: "Shift updated successfully"
                })
                history.push("/shift");
            }else if(res.data.status === "validation_error"){
                setErrors(res.data.errors);
                console.log(errors);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
                setErrors(null);
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
                setErrors(null);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            if(err.response.status === 401){
                Toast.fire({
                    icon: 'error',
                    title: "Unauthorized Access"
                })
            }
            console.log(err);
        });
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h3>Update Shift</h3>
                    </div>
                    <form onSubmit={handleSubmit} className="row justify-content-center">
                        <div className="card-body col-8">
                            <ValidateInput 
                                title="Shift Name"
                                id="shiftName"
                                value={name}
                                type="text"
                                errors={errors}
                                searchFor="name"
                                setValue={setName}
                            />
                            <ValidateInput 
                                title="Shift From"
                                id="shiftFrom"
                                value={from}
                                type="time"
                                errors={errors}
                                searchFor="from"
                                setValue={setFrom}
                            />
                            <ValidateInput 
                                title="Shift To"
                                id="shiftTo"
                                value={to}
                                type="time"
                                errors={errors}
                                searchFor="to"
                                setValue={setTo}
                            />
                            {!isLoading && <button className="btn btn-primary mt-3">UPDATE CHANGES</button>}
                            {isLoading && <button className="btn btn-primary mt-3" disabled><i className="fas fa-spinner fa-spin mr-1"></i> UPDATING CHANGES...</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateShift;