import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const ViewSubscriptionPlan = () => {
    const [plans, setPlans] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(null);

    let history = useHistory();

    const getToken = () => { 
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
        }
    }

    const getSubscription = () => { 
        axios
        .post(BASE_URL+"/admin/subscription-plan-list", {},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data);
                setPlans(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if(token !== null){
            getSubscription();
        }
        // eslint-disable-next-line
    }, [token]);

    const deletePlan = (id) => {
        var accept = window.confirm("Are you sure you want to delete plan?");
        if(accept)
        {
            setIsLoading(true);

            const params = {
                id: id
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

            axios
            .post(BASE_URL+"/admin/delete-subscription-plan", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                setIsLoading(false);
                if(res.data.status === 'success'){
                    Toast.fire({
                        icon: 'success',
                        title: "Plan Deleted Successfully"
                    })
                    getSubscription();
                }else{
                    console.log(res.data);
                    Toast.fire({
                        icon: 'error',
                        title: "Unable to delete plan"
                    })
                }
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: "Unexpected Error"
                })
                setIsLoading(false);
                console.log(err);
            });
        }
    }

    return(
        <div className="container-fluid">
            <div className="card-body row">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{width: 10}}>ID</th>
                            <th>Subscription Plan</th>
                            <th>Duration</th>
                            <th>Price</th>
                            <th style={{width: 120}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && <tr><td className="text-center" colSpan="5">Loading Please wait...</td></tr>}
                        {(plans!==null)? 
                            (plans.length > 0)? 
                                plans.map((plan, i) => (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{plan.plan_name}</td>
                                        <td>{plan.duration}</td>
                                        <td>{plan.price}</td>
                                        <td>
                                            <button className="btn btn-info btn-sm" onClick={() => history.push("/subscription-plan/update/"+plan._id)}><i className="fas fa-pen"></i></button>
                                            <button className="btn btn-danger btn-sm ml-1" onClick={() => deletePlan(plan._id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))
                            :
                            <tr><td className="text-center" colSpan="5">No Data Found</td></tr>
                        :
                        null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewSubscriptionPlan;