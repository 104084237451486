import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';

import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const Employee = () => {
    const [users, setUsers] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState("");
    // eslint-disable-next-line
    const [limit, setLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);

    let history = useHistory();

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const getToken = () => { 
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
            setUser(JSON.parse(localStorage.getItem('user')))
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
            setUser(JSON.parse(sessionStorage.getItem('user')))
        }
    }

    const getUsers = () => { 
        var id = ""
        if(user.user_type === "company"){
            id = user._id;
        }

        const params = {
            id: id,
            name:"",
            limit: limit,
            page: pageNumber * limit
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/employee-list", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data.totalCount);
                setTotalUsers(res.data.totalCount);
                setUsers(res.data.result);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if(token !== ""){
            getUsers();
        }
        // eslint-disable-next-line
    }, [token, limit, pageNumber]);

    const deleteUser = (id) => {
        var accept = window.confirm("Are you sure you want to delete employee?");
        if(accept)
        {
            setIsLoading(true);

            const params = {
                id: id
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

            axios
            .post(BASE_URL+"/admin/delete-employee", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                setIsLoading(false);
                if(res.data.status === 'success'){
                    Toast.fire({
                        icon: 'success',
                        title: "Employee deleted successfully"
                    })
                    getUsers();
                }else{
                    console.log(res.data);
                    Toast.fire({
                        icon: 'error',
                        title: "Unable to delete employee"
                    })
                }
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: "Unexpected error"
                })
                setIsLoading(false);
                console.log(err);
            });
        }
    }

    const updateStatus = (status, id) => {
        var accept = window.confirm(`Are you sure you want to ${(status === 0)? "Deactivate" : "Activate"} employee?`);
        if(accept)
        {
            const params = {
                id: id,
                status: status,
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

            axios
            .post(BASE_URL+"/admin/update-employee-status", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                if(res.data.status === 'success'){
                    Toast.fire({
                        icon: 'success',
                        title: "Employee status updated"
                    })
                    getUsers();
                }else{
                    console.log(res.data);
                    Toast.fire({
                        icon: 'error',
                        title: "Unable to update status"
                    })
                }
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: "Unexpected Error"
                })
                console.log(err);
            });
        }
    }

    return(
        <div className="container-fluid scroll-x">
            <div className="card-body row">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{width: 10}}>ID</th>
                            <th>Employee Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Company</th>
                            <th>Department</th>
                            <th>Shift</th>
                            <th>Designation</th>
                            <th>Status</th>
                            <th style={{minWidth: 150}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && <tr><td className="text-center" colSpan="10">Loading Please wait...</td></tr>}
                        {(users!==null)? 
                            (users.length > 0)? 
                                users.map((user, i) => (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{(user.company != null)?user.company.full_name:null}</td>
                                        <td>{(user.department != null)?user.department.name:null}</td>
                                        <td>{(user.shift)?user.shift.name:null}</td>
                                        <td>{user.designation}</td>
                                        <td>{(user.active === 0) ? <button className="btn btn-danger btn-sm" onClick={() => updateStatus(1, user._id)}><i className="fas fa-thumbs-down"></i></button>: <button className="btn btn-success btn-sm" onClick={() => updateStatus(0, user._id)}><i className="fas fa-thumbs-up"></i></button>}</td>
                                        <td>
                                            <button className="btn btn-primary btn-sm" onClick={() => history.push("/employee/attendance/"+user._id)}><i className="fa fa-clipboard"></i></button>
                                            <button className="btn btn-info btn-sm ml-1" onClick={() => history.push("/employee/update/"+user._id)}><i className="fas fa-pen"></i></button>
                                            <button className="btn btn-danger btn-sm ml-1" onClick={() => deleteUser(user._id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))
                            :
                            <tr><td className="text-center" colSpan="10">No Data Found</td></tr>
                        :
                        null
                        }
                    </tbody>
                </table>
            </div>
            <div>
                {(users != null)?
                    <ReactPaginate 
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={Math.ceil(totalUsers / limit)}
                        onPageChange={changePage}
                        containerClassName={"pagination mt-3"}
                        pageClassName={"paginate_button page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"previous"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"next"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                    />
                :null}
            </div>
        </div>
    )
}

export default Employee;