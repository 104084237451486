import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//Navigation
import Sidebar from './components/Navigation/Sidebar';
import Topbar from './components/Navigation/Topbar';

export const ProtectedRoute = ({component: Component, ...rest}) => {
    const isLocalAuth = localStorage.getItem('isLoggedIn');
    const isSessionAuth = sessionStorage.getItem('isLoggedIn');
    return (
        <Route
            {...rest} 
            render={props => {
                if(isLocalAuth || isSessionAuth)
                {
                    return (
                        <div>
                            {/* <!-- Page Wrapper --> */}
                            <div id="wrapper">
                                {/* <!-- Sidebar --> */}
                                <Sidebar />
                                {/* <!-- End of Sidebar --> */}

                                {/* <!-- Content Wrapper --> */}
                                <div id="content-wrapper" className="d-flex flex-column">
                                    {/* <!-- Main Content --> */}
                                    <div id="content">
                                        {/* <!-- Topbar --> */}
                                        <Topbar />
                                        {/* <!-- End of Topbar --> */}

                                        <Component {...props}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }else{
                    return <Redirect to={
                        {
                            pathname: "/",
                            state: {
                                from: props.location
                            }
                        }
                    }/>
                }
            }
        }/>
    );
};