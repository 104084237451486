import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import ValidateInput from '../../components/ValidateInput'
import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const Company = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [logo, setLogo] = useState({
        image: null,
        base: null
    });

    let history = useHistory();

    useEffect(() => {
        let isLocalAuth = localStorage.getItem('isLoggedIn')
        let isSessionAuth = sessionStorage.getItem('isLoggedIn');
    
        if(isLocalAuth){
            setToken(localStorage.getItem('token'))
        }
        else if(isSessionAuth){
            setToken(sessionStorage.getItem('token'))
        }
    }, []);
    
    function handleSubmit(event) 
    {
        event.preventDefault();

        if(name === "" || email === "" || mobile === "" || password === ""){
            Toast.fire({
                icon: 'error',
                title: "Fill required fields"
            })
        }else{
            setIsLoading(true);

            let formData = new FormData();
            formData.append('photo', logo.image);
            formData.append('full_name', name);
            formData.append('email', email);
            formData.append('mobile', mobile);
            formData.append('password', password);

            axios
            .post(BASE_URL+"/admin/create-user", formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                setIsLoading(false);
                if(res.data.status === 'success'){
                    Toast.fire({
                        icon: 'success',
                        title: "Company created successfully"
                    })
                    console.log(res.data);
                    history.push('/company');
                }else if(res.data.status === "validation_error"){
                    setErrors(res.data.errors);
                    console.log(errors);
                }else if(res.data.status === "error"){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.message
                    })
                    setErrors(null);
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: "Cannot process request"
                    })
                    setErrors(null);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                Toast.fire({
                    icon: 'error',
                    title: "Unexpected Error"
                })
                console.log(err);
            });
        }
    }

    const handleFileUpload = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0]);
        let image_as_file = e.currentTarget.files[0];

        setLogo({
            image: image_as_file,
            base: image_as_base64
        });
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h3>Create Company</h3>
                    </div>
                    <form onSubmit={handleSubmit} className="row justify-content-center">
                        <div className="card-body col-8">
                            <ValidateInput 
                                title="Company Name"
                                id="companyName"
                                value={name}
                                type="text"
                                errors={errors}
                                searchFor="full_name"
                                setValue={setName}
                            />
                            <ValidateInput 
                                title="Company Email"
                                id="companyEmail"
                                value={email}
                                type="email"
                                errors={errors}
                                searchFor="email"
                                setValue={setEmail}
                            />
                            <ValidateInput 
                                title="Company Mobile"
                                id="companyMobile"
                                value={mobile}
                                type="text"
                                errors={errors}
                                searchFor="mobile"
                                setValue={setMobile}
                            />
                            <div className="form-group col-6">
                                <label className="mr-3">Company Logo</label>
                                <input type="file" name="file" className="ml-3" onChange={e => handleFileUpload(e)}/>
                            </div>
                            <ValidateInput 
                                title="Password"
                                id="password"
                                value={password}
                                type="password"
                                errors={errors}
                                searchFor="password"
                                setValue={setPassword}
                            />
                            {!isLoading && <button className="btn btn-primary mt-3">ADD</button>}
                            {isLoading && <button className="btn btn-primary mt-3" disabled><i className="fas fa-spinner fa-spin mr-1"></i> ADDING...</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Company;