import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenuOpen } from '../../../redux/actions';

class Topbar extends Component {
    constructor(){
      super();
      this.state = {
        user: null,
      };
    }

    componentWillMount(){  
      // Check if is Logged in
      let isLocalAuth = localStorage.getItem('isLoggedIn')
      let isSessionAuth = sessionStorage.getItem('isLoggedIn');
  
      if(isLocalAuth){
        this.setState({ user: JSON.parse(localStorage.getItem('user')) })
      }
      else if(isSessionAuth){
        this.setState({ user: JSON.parse(sessionStorage.getItem('user')) })
      }
    }

    logOut(){
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      sessionStorage.removeItem('isLoggedIn');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      this.props.history.push("/");
    }

    render() {
      const { clickMenuOpen } = this.props;

        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button onClick={() => { clickMenuOpen() }}  id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <i className="fa fa-bars"></i>
            </button>

            {/* <!-- Topbar Navbar --> */}
            <ul className="navbar-nav ml-auto">
              <div className="topbar-divider d-none d-sm-block"></div>

              {/* <!-- Nav Item - User Information --> */}
              <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" href="#dropdown" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(this.state.user !== null)? this.state.user.full_name :null}</span>
                  <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" alt="User"/>
                </a>
                {/* <!-- Dropdown - User Information --> */}
                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <Link className="dropdown-item" to="/change-password">
                    <i className="fas fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                    Change Password
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/" data-toggle="modal" data-target="#logoutModal" onClick={() => this.logOut()}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                  </Link>
                </div>
              </li>

            </ul>

          </nav>
         
        )
    }
}


const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = store => ({
  toggled: store.menuState.menuOpen
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));