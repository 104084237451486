import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import ValidateInput from '../../components/ValidateInput'
import { BASE_URL } from '../../env';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const UpdateEmployee = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [department, setDepartment] = useState("");
    const [shift, setShift] = useState("");
    const [designation, setDesignation] = useState("");

    const [companies, setCompanies] = useState("");
    const [departments, setDepartments] = useState("");
    const [shifts, setShifts] = useState("");
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    let { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        let isLocal = localStorage.getItem('isLoggedIn');
        let isSession = sessionStorage.getItem('isLoggedIn');
        if(isLocal){
            setToken(localStorage.getItem('token'));
        }else if(isSession){
            setToken(sessionStorage.getItem('token'));
        }
    }, [])

    useEffect(() => {
        if(token !== null){
            const params = {
                id: id
            }
    
            // converting (json --> form-urlencoded)
            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    
            axios
            .post(BASE_URL+"/admin/get-employee", data,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                    }
                })
            .then((res) => {
                // Validating form
                if(res.data.status === 'success'){
                    console.log(res.data);
                    setName(res.data.result.name);
                    setCompany(res.data.result.company);
                    setDepartment(res.data.result.department);
                    setShift(res.data.result.shift);
                    setDesignation(res.data.result.designation);
                }else if(res.data.status === "error"){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.message
                    })
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: "Cannot process request"
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [token, id])

    const getCompanies = () => {
        const params = {
            user_type: "company"
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/user-list", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            if(res.data.status === 'success'){
                setCompanies(res.data.result);
            }else if(res.data.status === "error"){
                console.log(res.data.message);
            }else{
                console.log(res.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getDepartment = () => { 
        axios
        .post(BASE_URL+"/admin/department-list", {},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            if(res.data.status === 'success'){
                console.log(res.data);
                setDepartments(res.data.result);
            }else if(res.data.status === "error"){
                console.log(res.data.message);
            }else{
                console.log(res.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getShifts = () => { 
        axios
        .post(BASE_URL+"/admin/shift-list", {},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            if(res.data.status === 'success'){
                console.log(res.data);
                setShifts(res.data.result);
            }else if(res.data.status === "error"){
                console.log(res.data.message);
            }else{
                console.log(res.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        if(token !== null){
            getCompanies();
            getDepartment();
            getShifts();
        }
        // eslint-disable-next-line
    }, [token]);

    function handleSubmit(event){
        event.preventDefault();
        setIsLoading(true);

        const params = {
            id: id,
            name: name,
            company: company,
            department: department,
            shift: shift,
            designation: designation
        }

        // converting (json --> form-urlencoded)
        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

        axios
        .post(BASE_URL+"/admin/update-employee", data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
                }
            })
        .then((res) => {
            // Validating form
            setIsLoading(false);
            if(res.data.status === 'success'){
                console.log(res.data);
                Toast.fire({
                    icon: 'success',
                    title: "Employee updated successfully"
                })
                history.push('/employee');
            }else if(res.data.status === "validation_error"){
                setErrors(res.data.errors);
                console.log(errors);
            }else if(res.data.status === "error"){
                Toast.fire({
                    icon: 'error',
                    title: res.data.message
                })
                setErrors(null);
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "Cannot process request"
                })
                setErrors(null);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            if(err.response.status === 401){
                Toast.fire({
                    icon: 'error',
                    title: "Unauthorized Access"
                })
            }
            console.log(err);
        });
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h3>Update Employee</h3>
                    </div>
                    <hr />
                    <form onSubmit={handleSubmit} className="row justify-content-center">
                        <div className="card-body col-8">
                            <ValidateInput 
                                title="Employee Name"
                                id="employeeName"
                                value={name}
                                type="text"
                                errors={errors}
                                searchFor="name"
                                setValue={setName}
                            />
                            <div className="form-group">
                                <label htmlFor="company">Company <span className='text-danger'>*</span></label>
                                <select className="form-control" id="company" value={company} onChange={(e) => setCompany(e.target.value)}>
                                    <option>Select Company</option>
                                    {
                                        (companies != null)? 
                                            (companies.length > 0)?
                                                companies.map(company => (
                                                    <option value={company._id}>{company.full_name}</option>
                                                ))
                                            :null
                                        :null
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="department">Department <span className='text-danger'>*</span></label>
                                <select className="form-control" id="department" value={department} onChange={(e) => setDepartment(e.target.value)}>
                                    <option>Select Department</option>
                                    {
                                        (departments != null)? 
                                            (departments.length > 0)?
                                                departments.map(department => (
                                                    <option value={department._id}>{department.name}</option>
                                                ))
                                            :null
                                        :null
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="shift">Shift <span className='text-danger'>*</span></label>
                                <select className="form-control" id="shift" value={shift} onChange={(e) => setShift(e.target.value)}>
                                    <option>Select Shift</option>
                                    {
                                        (shifts != null)? 
                                            (shifts.length > 0)?
                                                shifts.map(shift => (
                                                    <option value={shift._id}>{shift.name}</option>
                                                ))
                                            :null
                                        :null
                                    }
                                </select>
                            </div>
                            <ValidateInput 
                                title="Designation"
                                id="designation"
                                value={designation}
                                type="text"
                                errors={errors}
                                searchFor="designation"
                                setValue={setDesignation}
                            />
                            {!isLoading && <button className="btn btn-primary mt-3">UPDATE CHANGES</button>}
                            {isLoading && <button className="btn btn-primary mt-3" disabled><i className="fas fa-spinner fa-spin mr-1"></i> UPDATING CHANGES...</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateEmployee;