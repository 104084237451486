import React, { useState, useEffect } from "react";
import { useLoadScript, GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import axios from 'axios';
import moment from 'moment';

import { BASE_URL } from '../../env';

function GMap() {
  const [activeMarker, setActiveMarker] = useState(null);
  const [user, setUser] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [token, setToken] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    let isLocal = localStorage.getItem('isLoggedIn');
    let isSession = sessionStorage.getItem('isLoggedIn');
    if(isLocal){
        setToken(localStorage.getItem('token'));
        setUser(JSON.parse(localStorage.getItem('user')))
    }else if(isSession){
        setToken(sessionStorage.getItem('token'));
        setUser(JSON.parse(sessionStorage.getItem('user')))
    }
  },[])

  const getUsers = () => {
    let markersArr = [];

    var id = ""
    if(user.user_type === "company"){
        id = user._id;
    }

    let startDate = moment(new Date(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').startOf('day').toString();
    let endDate = moment(new Date(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').endOf('day').toString();
    
    const params = {
        employee: "",
        limit: 500,
        page: 0,
        start: startDate,
        end: endDate,
        id: id
    }

    // converting (json --> form-urlencoded)
    const data = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

    axios
    .post(BASE_URL+"/admin/employee-attend-list", data,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': token
            }
        })
    .then((res) => {
        // Validating form
        if(res.data.status === 'success'){
            // eslint-disable-next-line
            res.data.result.map(user => {
              markersArr.push({
                id: user._id,
                name: user.employee.name,
                position: { lat: user.position.coordinates[0], lng: user.position.coordinates[1] }
              })
            })
            setMarkers(markersArr);
        }else if(res.data.status === "error"){
            console.log(res.data.message);
        }else{
          console.log("Cannot process request");
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }

  useEffect(() => {
    if(token !== null && user !== null){
      getUsers();
    }
    // eslint-disable-next-line
  }, [token, user]);

  return (
    <GoogleMap
      center={{ lat: 23.93154, lng: 77.1932752 }}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "80vw", height: "70vh" }}
      zoom={4}
    >
      {markers !== null?
        markers.map(({ id, name, position }) => (
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{name}</div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))
      :null}
    </GoogleMap>
  );
}

export default function Map() {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyCO5CDU2-xVi6VRy14HhptZ3A8Bztx5Ps4" // Add your API key
    });
  
    return isLoaded ? <GMap /> : null;
}

// export default Map;